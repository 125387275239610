import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comments-suggestions',
  templateUrl: './comments-suggestions.component.html',
  styleUrls: ['./comments-suggestions.component.scss']
})
export class CommentsSuggestionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
