import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-patient',
  templateUrl: './ai-patient.component.html',
  styleUrls: ['./ai-patient.component.scss']
})
export class AiPatientComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
