import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ihc-wish-list',
  templateUrl: './ihc-wish-list.component.html',
  styleUrls: ['./ihc-wish-list.component.scss']
})
export class IhcWishListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
