import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-satisfaction-survey',
  templateUrl: './customer-satisfaction-survey.component.html',
  styleUrls: ['./customer-satisfaction-survey.component.scss']
})
export class CustomerSatisfactionSurveyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
