import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-directory',
  templateUrl: './staff-directory.component.html',
  styleUrls: ['./staff-directory.component.scss']
})
export class StaffDirectoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
