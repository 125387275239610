import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cita-medica',
  templateUrl: './cita-medica.component.html',
  styleUrls: ['./cita-medica.component.scss']
})
export class CitaMedicaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
