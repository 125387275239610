import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-directors',
  templateUrl: './board-directors.component.html',
  styleUrls: ['./board-directors.component.scss']
})
export class BoardDirectorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
