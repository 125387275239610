import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-services',
  templateUrl: './medical-services.component.html',
  styleUrls: ['./medical-services.component.scss']
})
export class MedicalServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
